import React from 'react'
import Layout from '../components/Layout'

export default function NotFound() {
    return (
      <Layout>
        <div>
          <h2>404</h2>
          <p>Sorry, that page doesn't exist</p>
        </div>
      </Layout>
    )
}
